import React from 'react';
import { useState, useEffect } from 'react'
import IntroStraylight from '../blog/intro-straylight.md'
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ReactDom from 'react-dom'

export default function Intro() {

    const [tosText, setTosText] = useState('')

    useEffect(() => {
		fetch(IntroStraylight).then(res => res.text()).then(text => setTosText(text))
	})

    return(
        <>
            <div className= 'float-right font-semibold text-xs'>
                Last updated: 2021-04-26
                <br></br>
                Published: 2021-04-26
            </div>
            <ReactMarkdown children={tosText} remarkPlugins={[remarkGfm]} />
        </>
    )
}