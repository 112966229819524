import React from 'react';
import logo from '../img/Brevity-Logo-Circle.png'
import spaceshipbw from '../img/logo-spaceship-bw.jpg';
import {Link} from 'react-router-dom'
import { FaBars } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';

export default function NavBar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between bg-black border-black-900 px-2 py-3 mb-3 square dark:bg-black-900">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">     
          <div className="w-full relative flex justify-between md:w-auto md:static md:block md:justify-start"> 
            <a href="https://www.brevityinmotion.com/" className="flex items-center">
            {/* <img src={spaceshipbw} className='h-12 mr-3 bg-blend-darken' alt="Space Ship" /> */}
            <span className="self-center text-6xl font-montana text-white font-semibold whitespace-nowrap dark:text-white">Brevity In Motion</span>
            </a>

            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
              >
              <FaBars />
            </button>

          </div>

        <div className={"md:flex flex-grow items-right" + (navbarOpen ? " flex" : " hidden w-full md:block md:w-auto")} id="brevity-navbar">
          <ul className="flex flex-col font-montana text-white text-2xl font-bold p-4 mt-4 rounded-lg md:flex-row list-none md:ml-auto md:space-x-8 md:mt-0 md:text-xl2 md:font-bold md:border-0 sm:bg-black dark:bg-black sm:dark:bg-black">
          
            <li>
              <Link to="/" className="block py-2 pl-3 pr-4 rounded hover:bg-transparent md:border-0 hover:text-red-600 md:p-0" aria-current="page">Home</Link>
            </li>
            <li>
              <Link to="/recon" className="block py-2 pl-3 pr-4 rounded hover:bg-transparent md:border-0 hover:text-orange-500 md:p-0 sm:dark:hover:bg-transparent">Recon</Link>
            </li>
            <li>
              <Link to="/cloud" className="block py-2 pl-3 pr-4 rounded hover:bg-transparent md:border-0 hover:text-yellow-300 md:p-0 sm:dark:hover:bg-transparent">Cloud</Link>
            </li>
            <li>
              <Link to="/about" className="block py-2 pl-3 pr-4 rounded hover:bg-transparent md:border-0 hover:text-green-600 md:p-0 sm:dark:hover:bg-transparent">About</Link>
            </li>
            <li>
              <Link to="/learning" className="block py-2 pl-3 pr-4 rounded hover:bg-transparent md:border-0 hover:text-blue-600 md:p-0 sm:dark:hover:bg-transparent">Learning</Link>
            </li>
            <li>
              <Link to="/code" className="block py-2 pl-3 pr-4 rounded hover:bg-transparent md:border-0 hover:text-purple-600 md:p-0 sm:dark:hover:bg-transparent">Code</Link>
            </li>
          </ul>
          
        </div>
       
      </div>
      </nav>
      <hr className = "h-2 border-0 bg-gradient-to-r from-indigo-600 via-purple-500 to-pink-500" />
    </>
  );
 }