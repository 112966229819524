import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import NavBar from './components/navbar';
import RemoveTrailingSlash from './components/removeslash';
import Home from './components/home';
import Intro from './pages/intro';
import About from './pages/about';
import Recon from './pages/recon';
import Learning from './pages/learning';
import Sonar from './pages/sonar';
import Threat from './pages/threat';
import Cloud from './pages/cloud';
import './App.css';
import { useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
//import { useFetch } from 'react-async';

export default function App() {
  return (
    <>
    <div className = 'bg-black text-white'>
      <div className = 'prose prose-invert max-w-none bg-black md:mx-20 md:mx-8 mx-4 my-8'>
        <RemoveTrailingSlash />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/recon' element={<Recon />} />
          <Route path='/learning' element={<Learning />} />
          <Route path='/intro' element={<Intro />} />
          <Route path='/sonar' element={<Sonar />} />
          <Route path='/threat' element={<Threat />} />
          <Route path='/cloud' element={<Cloud />} />

          <Route path='/bug-bounties-cloud-automation-at-scale' element={<Recon />} />
          <Route path='/automated-cloud-based-recon' element={<Recon />} />
          <Route path='/introduction-project-straylight-security-accelerators-mentoring-and-collaboration' element={<Intro />} />
          <Route path='/external-ip-domain-reconnaissance-and-attack-surface-visualization-in-under-2-minutes' element={<Sonar />} />
          <Route path='/cloud-threat-modeling' element={<Threat />} />
          <Route path='/living-off-the-cloud-security-insights-and-predictions' element={<Cloud />} />
        </Routes>
      </div>
      <div className = 'footer bg-black text-white'>
      <p>Brevity In Motion &copy; 2023</p>
      </div>
    </div>
    </>
  );
}