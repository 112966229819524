import React from 'react';
import { useState, useEffect } from 'react'
import AutoRecon from '../blog/automated-cloud-based-recon.md'
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ReactDom from 'react-dom'
import { dracula } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

export default function Learning() {

    const [tosText, setTosText] = useState('')

    useEffect(() => {
		fetch(AutoRecon).then(res => res.text()).then(text => setTosText(text))
	})

    return(
        <>
            <div className= 'float-right font-semibold text-xs'>
                Last updated: 2021-12-03
                <br></br>
                Published: 2021-08-03
            </div>
            <ReactMarkdown
              children={tosText}
              remarkPlugins={[remarkGfm]} 
              components={{
                code({ node, inline, className, children, style, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");

                    return !inline && match ? (
                        <SyntaxHighlighter
                            style={dracula}
                            PreTag="div"
                            language={match[1]}
                            children={String(children).replace(/\n$/, "")}
                            {...props}
                            />
                    ) : (
                        <code className={className ? className : ""} {...props}>
                            {children}
                        </code>
                    );
                }
            }}  
            />
        </>
    )
}