import React from 'react';
import { useState, useEffect } from 'react'
import AboutMe from '../blog/about.md'
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ReactDom from 'react-dom'

export default function About() {
    const [tosText, setTosText] = useState('')

    useEffect(() => {
		fetch(AboutMe).then(res => res.text()).then(text => setTosText(text))
	})

    return(
        <>
        <div className= 'float-right font-semibold text-xs'>
        Last updated: 2023-03-12
        <br></br>
        Published: 2021-07-03
        </div>
        <ReactMarkdown children={tosText} remarkPlugins={[remarkGfm]} />
        </>
    )
}