import React from 'react';
import spaceshipcolor from '../img/logo-spaceship-color.jpg';
import spaceshipbw from '../img/logo-spaceship-bw.jpg';
import spaceshipdark from '../img/logo-spaceship-dark.jpg';

export default function Home() {
    
    return (
        <div className='home'>
            <img src={spaceshipdark} className='bg-blend-darken h-96' alt="Space Ship" />
        </div>
    )
}