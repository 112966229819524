import React from 'react';
import { useState, useEffect} from 'react'
import CloudInsights from '../blog/cloudinsights.md'
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ReactDom from 'react-dom'
import { dracula } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import rehypeRaw from "rehype-raw";

export default function Cloud() {

    const [tosText, setTosText] = useState('')    

    useEffect(() => {
		fetch(CloudInsights).then(res => res.text()).then(text => setTosText(text))
	})

    const [input,setInput] = useState();

    return(
        <>
            <div className= 'float-right font-semibold text-xs'>
                Last updated: 2022-02-08
                <br></br>
                Published: 2021-12-10
            </div>
 
            <ReactMarkdown 
                children={tosText}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]} linkTarget="_blank"
                components={{
                    code({ node, inline, className, children, style, ...props }) {
                        const match = /language-(\w+)/.exec(className || "");
    
                        return !inline && match ? (
                            <SyntaxHighlighter
                                style={dracula}
                                PreTag="div"
                                language={match[1]}
                                children={String(children).replace(/\n$/, "")}
                                {...props}
                                />
                        ) : (
                            <code className={className ? className : ""} {...props}>
                                {children}
                            </code>
                        );
                    }
                }}
            />
        </>
    )

}